@import "../_settings.scss";

#giftcertificatePreview .adv-modal-inner {
    border-radius: 40px;
}



.certificate {
    width: 686px;
    min-height: 295px;

    &-modal {
        padding: 0 !important;
        border-radius: 30px;
        margin: 0;
    }
}

.certifiacte-custom {
    width: 711px;
    min-height: 335px;
    background: url(../../images/giftcertificate/giftcertificatbg-new2.png) no-repeat;
    border-radius: 25px;
    background-size: 100% 100%;

    &__persons {
        display: flex;
        padding-left: 30px;
        margin-top: 15px;
        max-width: 400px;
        flex-wrap: wrap;
    }

    &__person-to span:first-child,
    &__person-from span:first-child {
        font-size: 14px;
        /*font-family: "Times New Roman";*/
        font-family: "Circe",sans-serif;
        color: rgba( 83, 88, 93, 0.6 );
        text-align: left;
    }

    &__person-to span:last-child,
    &__person-from span:last-child {
        font-size: 12px;
        /*font-family: "ClearSans";*/
        font-family: "Circe",sans-serif;
        color: rgb( 83, 88, 93 );
        margin-left: 12px;
    }

    &__person-to {
        margin-right: 30px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }

    &__person-from {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }

    &__bow {
        position: absolute;
        right: 40px;
        top: 1%;
    }

    &__cert-price {
        position: absolute;
        right: 118px;
        width: 100px;
        height: 100px;
        background: #FF2728;
        border: 10px solid #D30713;
        border-radius: 50%;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.71);
        text-align: center;
        bottom: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
            font-size: 24px;
            /*font-family: "ClearSans";*/
            font-family: "Circe",sans-serif;
            color: rgb( 255, 255, 255 );
            font-weight: bold;
        }
    }

    &__cert-price-mobile {
        display: none;
    }

    &__message {
        margin-top: 12px;
        padding-left: 30px;
        max-width: 400px;
        line-height: 1;

        span {
            font-size: 14px;
            /*font-family: "ClearSans";*/
            font-family: "Circe",sans-serif;
            color: rgb( 83, 88, 93 );
            line-height: 1;
        }
    }

    &__use {
        padding-left: 30px;
        margin-top: 12px;
        /*font-family: "ClearSans";*/
        font-family: "Circe",sans-serif;
        color: rgba( 83, 88, 93, 0.6 );
        max-width: 400px;
        line-height: 1;
        padding-bottom: 30px;
        font-size: 14px;

        .site-use {
            color: rgba( 83, 88, 93, 0.6 );
        }
    }

    &__code-wrap {
        font-size: 16px;
        /*font-family: "ClearSans";*/
        font-family: "Circe",sans-serif;
        color: rgb( 255, 255, 255 );
        display: flex;
        transform: rotate( -90deg);
        max-width: 500px;
        position: absolute;
        bottom: 100px;
        right: -45px;
    }

    &__code {
        margin-left: 5px;
    }
}

#giftcertificatePreview .modal-content {
    background: #e6e6e6;
}

.certifiacte-custom .header {
    padding: 30px;
    height: auto;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.certifiacte-custom .descr {
    padding-left: 30px;
}

.certifiacte-custom .descr h2 {
    font-size: 60px;
    font-family: "Circe",sans-serif;
    color: rgb( 255, 41, 41 );
    margin: 0;
    line-height: 1.1;
    margin-left: -5px;
}

.certifiacte-custom .descr p {
    font-size: 36px;
    font-family: "Circe",sans-serif;
    color: rgb( 83, 88, 93 );
    margin: 0;
    line-height: 1.1;
}

.certificate .header {
    padding: 8px 0 8px 175px;
    font-family: Tahoma;
    border-bottom: 3px #00539a solid;
    margin-bottom: 2px;
}

.certificate .logo-wrap {
    float: left;
}

.certificate .logo-wrap img {
    max-width: 270px;
}

.certificate .code-wrap {
    float: right;
    width: 200px;
}

.certificate .code-wrap .text {
    color: #b80700;
    padding-bottom: 10px;
}

.certificate .code-wrap .code {
    font-size: 18px;
    color: #393939;
}

.certificate .section {
    background: #e7f5ff url(../../images/giftcertificate/bg_certificate.jpg) no-repeat scroll center bottom;
    position: relative;
    /* font-family: "Times New Roman";*/
    font-family: "Circe",sans-serif;
    color: #00539a;
    padding: 10px 0;
    min-height: 210px;
    -webkit-print-color-adjust: exact;
}

.certificate .section .cert-data {
    margin-left: 215px;
    padding-bottom: 25px;
}

.certificate .section .cert-data .name {
    padding-bottom: 20px;
}

.certificate .section .use {
    font-family: Tahoma;
    left: 214px;
    bottom: 5px;
    position: absolute;
    color: #000;
}

.certificate .section .persons {
    font-size: 17px;
    /*font-family: Times New Roman;*/
    font-family: "Circe",sans-serif;
    font-style: italic;
    padding-bottom: 15px;
}

.certificate .section .persons .person-to, .certificate .section .persons .person-from {
    float: left;
    margin-right: 40px;
    padding-top: 5px;
}

.certificate .section .persons .person-to img, .certificate .section .persons .person-from img {
    margin-right: 8px;
}

.certificate .section .persons .person-to img {
    vertical-align: middle;
}

.certificate .section .persons .person-from img {
    vertical-align: middle;
}

.certificate .section .message {
    width: 250px;
    background: url(../../images/giftcertificate/line.png) repeat-y scroll left 30px;
    font-size: 16px;
    font-style: italic;
    /*font-family: Times New Roman;*/
    font-family: "Circe",sans-serif;
    line-height: 31px;
    position: relative;
    float: left;
    -webkit-print-color-adjust: exact;
}

.certificate .section .site-use {
    color: #000;
    font-weight: bold;
}

.certificate .section .cert-price {
    font-family: Tahoma;
    font-weight: bold;
    font-size: 24px;
    color: #b80700;
    position: absolute;
    width: 200px;
    right: 10px;
    bottom: 60px;
    text-align: center;
}

.certificate .section .cert-price .curr {
    font-size: 24px;
}

.curr {
    display: inline-block;
    vertical-align: middle;
}

.certificate .bow-wrap {
    background: url(../../images/giftcertificate/bow-bg.png) repeat-y scroll 80px top;
    position: absolute;
    width: 209px;
    left: 0px;
    top: 0px;
    height: 100%;
    -webkit-print-color-adjust: exact;
}

.certificate .heighter {
    min-height: 60px;
    float: right;
}

.certificate .bow {
    background: url(../../images/giftcertificate/bow.png) no-repeat;
    height: 293px;
    -webkit-print-color-adjust: exact;
}

.block-certificate {
    cursor: pointer;
    font-size: $font-size-medium;
    padding: 0 10px 10px 0;
    margin-bottom: 10px;
}

.block-certificate:after {
    background: transparent none;
}

.block-certificate .block-certificate-txt {
    background: #3d597a;
    padding: 10px 15px;
    color: #fff;
    text-transform: uppercase;
}

.block-certificate img.certificate-img {
    display: block;
    width: 100%;
}


.gift-sertificate-block {
    .breadcrumbs {
        padding-left: 0;
        padding-right: 0;
    }
}


@media(max-width:768px) {
    #giftcertificatePreview .modal-content {
        background: #ffffff;
    }
    #giftcertificatePreview .adv-modal-inner {
        width: calc(100% - 20px);
        max-width: 500px;
    }
    .certifiacte-custom {
        width: 100%;
        min-height: 235px;
        height: auto;
        background-size: 400px 335px;
        background-position-x: calc(100% + 20px);

        &__persons {
            padding-left: 16px;
            margin-top: 8px;
            max-width: 60%;
            flex-wrap: wrap;
        }

        &__person-to span:first-child,
        &__person-from span:first-child {
            font-size: 11px;
        }

        &__message {
            margin-top: 10px;
            padding-left: 16px;
            max-width: 60%;

            span {
                font-size: 11px;
            }
        }

        &__use {
            padding-left: 16px;
            margin-top: 11px;
            max-width: 60%;
            padding-bottom: 20px;
            font-size: 11px;
        }

        &__code-wrap {
            font-size: 11px;
            max-width: 300px;
            position: absolute;
            bottom: 80px;
            right: -40px;
        }
    }

    .certifiacte-custom__bow {
        width: 100px;
        right: 30px;
        top: 6%
    }

    .certifiacte-custom__cert-price {
        width: 60px;
        height: 60px;
        right: 40px;
        bottom: 7%;

        span {
            font-size: 12px !important;
        }
    }

    .certifiacte-custom .descr h2 {
        font-size: 20px;
        margin-left: 0;
    }

    .certifiacte-custom .descr p {
        font-size: 14px;
    }

    .certifiacte-custom .header {
        padding: 20px 16px;
    }

    .certifiacte-custom .descr {
        padding-left: 16px;
    }

    .logo-wrap {
        max-width: 150px;
        max-height: 150px;
    }
}